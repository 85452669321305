







































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
// import html2pdf from 'html2pdf.js';
import { BIOGAS_INSURANCE_KEY } from '@/constant/constants';
import { calculateClaimsRequirementPremium,
  onCurrencyFocus } from '@/helper/functions';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';

export default Vue.extend({
  name: 'biogas-insurance-results-page',
  components: {
    DatePicker,
    FormSwitch,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'productId',
    'priceData',
    'AMSAPISendStatus',
  ],
  data(): any {
    return {
      name: 'biogas-insurance-results-page',
      key: BIOGAS_INSURANCE_KEY,
      pdfStyles: false,
      priceStep: 'results',
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: '',
        nettoisiert: '',
        steuerland: '',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      kalkulationsergebnis: {
        hazardGroups: {
          feuer: '',
          BU: '',
          maschinenBruch: '',
          bauleistungMontage: '',
        },
      },
      vorschadenhistorie: {
        select: false,
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: 0,
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: 0,
        Begrundung: '',
      },
      ergebnisseTarifkalkulationItems: {
        tarif: {
          pos: 'SB-Tarif SB',
          selbstbehalt: '',
          tarifPramie: '',
          laufzeit: '',
          dauernachlass: '',
          SVR: '',
          betrachtungszeitraum: '',
          nettoisierung: '',
          pramieInklTariflicherNachlasse: '',
        },
        variante1: {
          pos: 'SB-Variante 1',
          selbstbehalt: '',
          tarifPramie: '',
          laufzeit: '',
          dauernachlass: '',
          SVR: '',
          betrachtungszeitraum: '',
          nettoisierung: '',
          pramieInklTariflicherNachlasse: '',
        },
        variante2: {
          pos: 'SB-Variante 2',
          selbstbehalt: '',
          tarifPramie: '',
          laufzeit: '',
          dauernachlass: '',
          SVR: '',
          betrachtungszeitraum: '',
          nettoisierung: '',
          pramieInklTariflicherNachlasse: '',
        },
        variante3: {
          pos: 'SB-Variante 3',
          selbstbehalt: '',
          tarifPramie: '',
          laufzeit: '',
          dauernachlass: '',
          SVR: '',
          betrachtungszeitraum: '',
          nettoisierung: '',
          pramieInklTariflicherNachlasse: '',
        },
      },
      skillVollmachten: {
        TariffVariant: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant1: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant2: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant3: {
          estates: 0,
          surcharges: 0,
        },
        reason: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
      },
      promotionalRewards: {
        SBVariante1: {
          pos: 'SB-Variante 1',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
        SBVariante2: {
          pos: 'SB-Variante 2',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
        SBVariante3: {
          pos: 'SB-Variante 3',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
      },
      hinweiseZumBeitrag: '',
    };
  },
  mounted() {
    if (this.insuranceData.priceFormData?.results) {
      this.setValues(this.insuranceData.priceFormData[this.priceStep]);
    }
  },
  computed: {
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
  },
  methods: {
    onCurrencyFocus,
    onSubmit() {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        kalkulationsergebnis: this.kalkulationsergebnis,
        skillVollmachten: this.skillVollmachten,
        ergebnisseTarifkalkulationItems: this.ergebnisseTarifkalkulationItems,
        vorschadenhistorie: this.vorschadenhistorie,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };

      this.$emit('price-formData-changed', data, this.priceStep);
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.kalkulationsergebnis = priceFormData.kalkulationsergebnis;
      this.skillVollmachten = priceFormData.skillVollmachten;
      this.ergebnisseTarifkalkulationItems = priceFormData.ergebnisseTarifkalkulationItems;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    goToBackUrl() {
      const routeName = 'biogas-insurance-price-card';

      this.$emit('go-back-tab', routeName, 'priceForms', 1);
    },
    downloadPDF() {
      exportToPDF.call(this, `Zusammenfassung-Biogas-Preis-${this.id}.pdf`);
    },
    queueDownloadBigXML(variant) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onSubmit);
      this.$emit('add-to-queue', 'downloadBigXML', variant, 'BU');
      this.onSubmit();
    },
    queueSendBigXML(variant) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onSubmit);
      this.$emit('add-to-queue', 'sendBigXML', variant);
      this.onSubmit();
    },
  },
});
